import "./Slider.css"

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import {Swiper, SwiperSlide} from 'swiper/react'
import {Autoplay} from 'swiper/modules'

import Slide from "./Slide/Slide.jsx"
import {useEffect, useState} from "react"
import axios from "axios"

const Slider = () => {
    const [sliderData, setSliderData] = useState([])

    const getSliderData = () => {
        axios.get('https://donation.star-mc.ru/v1/donations/last?amount=10')
            .then((response) => {
                setSliderData(Object.values(response))
            })
            .catch(err => {
                console.error(err)
            })
    }

    useEffect(() => {
        getSliderData()

        setInterval(() => {
            getSliderData()
        }, 300000)
    }, [])

    return (
        <section className="slider">
            <div className="slider__background">
                <h1 className="slider__title">Только что оплатили</h1>
            </div>
            <Swiper
                spaceBetween={12}
                slidesPerView={7}
                // loop={true} // слайдер доходит до последнего слайда и останавливается
                allowTouchMove={false} // свойство запрещает трогать слайдер
                breakpoints={{
                    1800: {
                        slidesPerView: 7
                    },
                    1600: {
                        slidesPerView: 6
                    },
                    1400: {
                        slidesPerView: 5
                    },
                    1024: {
                        slidesPerView: 4
                    },
                    768: {
                        slidesPerView: 3
                    },
                    450: {
                        slidesPerView: 2
                    },
                    300: {
                        slidesPerView: 1
                    }
                }}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false
                }}
                modules={[Autoplay]}
            >
                {sliderData[0]?.map((data) => {
                    return (
                        <SwiperSlide key={data.id}>
                            <Slide data={data}/>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </section>
    )
}

export default Slider