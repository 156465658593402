import './Header.css'

import {
    arrow_down,
    button_gray,
    button_purple,
    header_earth,
    header_logo,
    header_title,
    successIcon,
    temp_account_icon,
    header_info_icon,
    header_info_icon_modal
} from "../../helper"
import {useEffect, useRef, useState} from "react"
import {Link} from "react-router-dom"
import {HashLink} from "react-router-hash-link"
import Modal from '../Modal/Modal'

import sound from "../../assets/MinecraftSTAR.mp3"
const audio = new Audio(sound)
audio.volume = 0.05

const Header = () => {
    const [activeAudio, setActiveAudio] = useState(false)
    const [modalActive, setModalActive] = useState(false)
    const [modalContent, setModalContent] = useState({
        modalImg: '',
        modalText: ''
    })
    const parralax = useRef()
    const earth = useRef()

    useEffect(() => {
        const forEarth = 20 // коэффициент

        const speed = 1 // скорость анимации

        let posX = 0, posY = 0
        let coordXprocent = 0, coordYprocent = 0

        function setMouseParralaxStyle() {
            const distX = coordXprocent - posX
            const distY = coordYprocent - posY

            posX += (distX * speed)
            posY += (distY * speed)

            if (window.innerWidth >= 1000) {
                earth.current.style.cssText += `transform: translate(${posX / forEarth}%, ${posY / forEarth}%);`
            }
            else {
                return;
            }

            requestAnimationFrame(setMouseParralaxStyle)
        }

        setMouseParralaxStyle()

        parralax.current.addEventListener("mousemove", (e) => {
            const parralaxWidth = parralax.current.offsetWidth
            const parralaxHeight = parralax.current.offsetHeight

            const coordX = e.pageX - parralaxWidth / 2
            const coordY = e.pageY - parralaxHeight / 2

            coordXprocent = coordX / parralaxWidth * 100
            coordYprocent = coordY / parralaxHeight * 100
        })
    }, [])

    return (
        <header ref={parralax} className="header section">
            <div className="background-video">
                <iframe
                    rel="preconnect"
                    src="https://player.vimeo.com/video/859768148?background=1&autoplay=1&loop=1&muted=1"
                    title="Header Background Video"
                    allowFullScreen
                />
            </div>

            <div className="background-image header__background-image"></div>

            <div className="navbar header__navbar">
                <div className="navbar__container container container_expand">
                    <div className="logo navbar__logo">
                        <img className="logo__icon" src={header_logo} alt="STAR Corp."/>
                        <div className="logo__text">STAR Corp.</div>
                    </div>

                    <div className="social navbar__social">
                        <div className="social__items">
                            <img className="social__item info" src={header_info_icon} alt="" onClick={() => {
                                // setModalActive(true)
                                // setModalContent({
                                //     modalImg: header_info_icon_modal,
                                //     modalText: 'Компания STAR Corporation в настоящее время занимается разработкой проекта в Minecraft индустрии. У нас создаются 9 уникальных и полноценных игровых режимов.\n' +
                                //         'Путешествуя в нашем мире, вы постепенно впитаетесь в атмосферу Стардиса и исследуете новые возможности.\n' +
                                //         'Заходи и начинай свое путешествие!'
                                // })
                                if (activeAudio) {
                                    audio.pause()
                                    setActiveAudio(false)
                                } else {
                                    audio.play()
                                    setActiveAudio(true)
                                }
                            }}/>
                            <Link className="social__item discord" to="https://discord.gg/DHrg6pXqnx" target="_blank">
                                <svg width="40" height="30" viewBox="0 0 40 29" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.87032 22.5868C17.3736 25.9516 24.6099 26.607 32.8102 21.3695C32.75 21.461 31.128 23.8976 26.8623 25.1464C27.7635 26.3953 28.9951 27.8268 28.9951 27.8268C31.7288 27.8268 34.4023 27.0349 36.7152 25.5119C38.5176 24.2935 39.5389 22.1919 39.3286 19.9989C38.9682 16.2524 38.097 12.5973 36.7453 9.09454C35.033 4.5257 30.9177 1.35791 26.1414 0.900991C25.7208 0.870517 25.4205 0.870517 25.2402 0.870517L24.7596 1.35782C30.2568 2.94171 32.9905 5.40888 33.0505 5.5003C24.6094 1.17507 14.6363 1.11421 6.13509 5.31755C6.13509 5.31755 8.83866 2.60669 14.8165 1.17507L14.456 0.80957C13.8251 0.80957 13.2243 0.870517 12.5936 0.961847C8.17775 1.72332 4.48295 4.79969 2.89082 9.0335C1.50898 12.6886 0.607824 16.5264 0.277414 20.4252C0.0971816 22.4964 1.05845 24.5372 2.74064 25.7251C4.96354 27.2176 7.607 28.0096 10.2805 28.0096C10.2805 28.0096 11.3619 26.578 12.4434 25.2987C8.38803 24.0803 6.73589 21.6436 6.70584 21.5522L7.46904 21.9474C7.92528 22.1837 8.39303 22.3979 8.87032 22.5868ZM13.5849 19.9379C11.6323 19.877 10.1003 18.2017 10.1604 16.1914C10.2205 14.303 11.7225 12.78 13.5849 12.7191C15.5375 12.78 17.0695 14.4552 17.0094 16.4656C16.9192 18.3541 15.4473 19.8771 13.5849 19.9379ZM25.8409 19.9379C23.8883 19.877 22.3563 18.2017 22.4164 16.1914C22.4765 14.303 23.9785 12.78 25.8409 12.7191C27.7935 12.78 29.3255 14.4552 29.2654 16.4656C29.2054 18.3541 27.7034 19.8771 25.8409 19.9379Z"
                                        fill="#fff"
                                    />
                                </svg>
                            </Link>
                            <Link className="social__item vkontakte" to="https://vk.com/star_mc_ru" target="_blank">
                                <svg width="40" height="30" viewBox="0 0 40 24" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M38.5612 2.08169C38.8268 1.1852 38.5612 0.525391 37.2832 0.525391H33.0544C31.9774 0.525391 31.482 1.09197 31.2164 1.72309C31.2164 1.72309 29.0625 6.96573 26.0183 10.3652C25.0347 11.3477 24.5824 11.6633 24.0439 11.6633C23.7783 11.6633 23.3691 11.3477 23.3691 10.4513V2.08169C23.3691 1.00591 23.0675 0.525391 22.1772 0.525391H15.5289C14.854 0.525391 14.452 1.02742 14.452 1.49359C14.452 2.512 15.974 2.74867 16.132 5.61742V11.8426C16.132 13.2053 15.8879 13.4563 15.3494 13.4563C13.9135 13.4563 10.4242 8.19212 8.35645 2.16775C7.94003 0.998735 7.53079 0.525391 6.44667 0.525391H2.21786C1.01169 0.525391 0.767578 1.09197 0.767578 1.72309C0.767578 2.84191 2.2035 8.40011 7.45181 15.7441C10.9483 20.7572 15.8735 23.4754 20.3536 23.4754C23.046 23.4754 23.3762 22.873 23.3762 21.833C23.3762 17.0422 23.1321 16.5904 24.4819 16.5904C25.1065 16.5904 26.1835 16.906 28.6963 19.3229C31.5682 22.1916 32.042 23.4754 33.6503 23.4754H37.8791C39.0853 23.4754 39.6955 22.873 39.3437 21.6824C38.5396 19.1794 33.1046 14.03 32.8605 13.6858C32.2359 12.8825 32.4154 12.5239 32.8605 11.8067C32.8677 11.7996 38.0299 4.54164 38.5612 2.08169Z"
                                        fill="#fff"
                                    />
                                </svg>
                            </Link>
                        </div>

                        <div className="account navbar__account">
                            <div className="account__item" onClick={() => {
                                setModalActive(true)
                                setModalContent({
                                    modalImg: temp_account_icon,
                                    modalText: 'Скоро...'
                                })
                            }}>Кабинет</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header__container container">
                <img rel="preload" ref={earth} className="header__earth" src={header_earth} alt=""/>

                <div className="header__logo">
                    <img className="header__logo-icon" src={header_title} alt=""/>
                    <div className="header__logo-text">Star Minecraft</div>
                </div>

                <div className="header__buttons">
                    <HashLink
                        className="header__buttons-item"
                        smooth to={{pathname: "/", hash: "#form-donate"}}
                    >
                        <p className="buttons-item__text buttons-item__text_text-white">Пополнить аккаунт</p>
                        <img src={button_purple} alt="Пополнить аккаунт"/>
                    </HashLink>

                    <button
                        className="header__buttons-item"
                        onClick={() => {
                            navigator.clipboard.writeText("PLAY.STAR-MC.RU")
                            setModalActive(true)
                            setModalContent({
                                modalImg: successIcon,
                                modalText: 'IP-адрес успешно скопирован!'
                            })
                        }}
                    >
                        <p className="buttons-item__text buttons-item__text_text-gray">Копировать IP</p>
                        <img src={button_gray} alt="Копировать IP"/>
                    </button>
                </div>

                <Modal
                    active={modalActive}
                    setActive={setModalActive}
                    img={modalContent.modalImg}
                    text={modalContent.modalText}
                />

                <img className="section-next" src={arrow_down} alt=""/>
            </div>
        </header>
    )
}

export default Header