import './Modal.css'

const Modal = ({ active, setActive, img, text }) => {
    return (
        <div className={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
            <div className="modal__container" onClick={e => e.stopPropagation()}>
                <img src={img} className="modal__img" alt=""/>

                <div className="modal__text">
                    <p>{text}</p>
                </div>

                <div className="modal__buttons">
                    <button className="modal__button-ok" onClick={() => setActive(false)}>OK</button>
                </div>
            </div>
        </div>
    )
}

export default Modal