import "./Main.css"

import Slider from "../Slider/Slider"
import DonateForm from "./DonateForm/DonateForm"

const Main = () => {
    return (
        <main className="main section" id="form-donate">
            <div className="main__container">
                <div className="main__container-bg"></div>
                <DonateForm />
            </div>

            <Slider />
        </main>
    )
}

export default Main