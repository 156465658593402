import "../Slider.css"

const Slide = ({data}) => {
    return (
        <div className="slider__item">
            <p className="slider__item-nick">{data.username}</p>
            <p className="slider__item-summary">{data.amount * 10} ₽</p>
        </div>
    )
}

export default Slide