import { useState } from "react"
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from "formik"
import axios from "axios"
import Modal from "../../Modal/Modal";

import {
    form_background,
    form_balance_divider,
    form_button,
    form_input_nick,
    form_input_sum,
    ruble_icon,
    info_icon,
    info_icon_modal
} from "../../../helper"

Yup.addMethod(Yup.number, "maximumSizeOfAmountField", function (errorMessage) {
    return this.test(`check-for-maximum-size-of-amount-field`, errorMessage, function (value) {
        const { path, createError } = this

        return (
            (value && /^[0-9]{0,20}$/.test(value)) ||
            createError({ path, message: errorMessage })
        )
    })
})

Yup.addMethod(Yup.number, "checkingMultiplicity", function (errorMessage) {
    return this.test(`check-for-multiplicity`, errorMessage, function (value) {
        const { path, createError } = this

        return (
            (value && value % 10 === 0) ||
            createError({ path, message: errorMessage })
        )
    })
})

const formSchema = Yup.object().shape({
    username: Yup.string()
        .min(3, 'Должно быть не меньше 3 символов')
        .matches(/^[a-zA-Z0-9_]+$/, 'Некорректный ввод')
        .required('Обязательно к заполнению'),
    amount: Yup.number()
        .moreThan(9, 'Минимальная сумма пополнения 10 рублей')
        .positive('Минимальная сумма пополнения 10 рублей')
        .checkingMultiplicity('Сумма должна быть кратная 10')
        .maximumSizeOfAmountField('Максимальный размер поля 20 символов')
        .required('Обязательно к заполнению')
})

const DonateForm = () => {
    const [data, setData] = useState({
        username: '',
        amount: '',
    })
    const [modalActive, setModalActive] = useState(false)
    const [modalContent, setModalContent] = useState({
        modalImg: '',
        modalText: ''
    })

    return (
        <Formik
            initialValues={data}
            validationSchema={formSchema}
            onSubmit={
                async (values, actions) => {
                    await axios.post('https://donation.star-mc.ru/v1/donations/request', values)
                        .then((response) => {
                            console.info(response)
                            window.location.replace(response.data)
                        })
                        .catch(err => {
                            console.error(err)
                            actions.setStatus({
                                username: 'Ошибка обработки запроса',
                                amount: 'Ошибка обработки запроса'
                            })
                        })
            }}
        >
            {({ status }) => (
                <Form
                    name="userForm"
                    className="main__form"
                    autoComplete="off"
                    noValidate
                >
                    <img loading="lazy" src={form_background} className="form__background" alt=""/>

                    <p className="form__title">Пополнение аккаунта</p>

                    <div className="form__item form__item_nick">
                        <Field
                            type="text"
                            name="username"
                            placeholder="Ваш ник"
                            minLength={3}
                            maxLength={16}
                        />
                        <img loading="lazy" src={form_input_nick} alt=""/>
                    </div>

                    <p className="input-error input-error_nick">
                        {status && status.username ? status.username : <ErrorMessage name="username" />}
                    </p>

                    <div className="form__item form__item_sum">
                        <div className="item__inner">
                            <Field
                                type="number"
                                name="amount"
                                placeholder="Сумма"
                                onKeyUp={(e) => {
                                    if (e.target.value.length <= 20 && e.target.value % 10 === 0 && e.target.value > 0)
                                        setData({
                                            ...data,
                                            [e.target.name]: (e.target.value.replace(/^(?:0+(?=[1-9])|0+(?=0$))/mg, '') / 10).toString()
                                        })
                                }}
                            />
                            <img loading="lazy" src={ruble_icon} alt=""/>
                        </div>
                        <img loading="lazy" src={form_input_sum} alt=""/>
                    </div>

                    <img src={info_icon} alt="" className="info-icon" onClick={() => {
                        setModalActive(true)
                        setModalContent({
                            modalImg: info_icon_modal,
                            modalText: "Форма предназначена для пополнения баланса на игровых серверах STAR Corp."
                        })
                    }}/>

                    <Modal
                        active={modalActive}
                        setActive={setModalActive}
                        img={modalContent.modalImg}
                        text={modalContent.modalText}
                    />

                    <p className="input-error input-error_sum">
                        {status && status.amount ? status.amount : <ErrorMessage name="amount" />}
                    </p>

                    <div className="form__balance">
                        <img loading="lazy" src={form_balance_divider} alt=""/>
                        <p>К зачислению: {data.amount.length >= 5 ? <span><br/>{data.amount}</span> : data.amount} ☬</p>
                    </div>

                    <button className="form__button form__button_finish" type="submit">
                        <p>Оплатить</p>
                        <img loading="lazy" src={form_button} alt="Кнопка оплаты"/>
                    </button>
                </Form>
            )}
        </Formik>
    )
}

export default DonateForm